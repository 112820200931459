import React, { useState, useEffect } from "react";
import "./App.css";
import * as firebase from "firebase";
import { useListVals } from "react-firebase-hooks/database";
import { useForm, Controller } from "react-hook-form";
import { DatePicker, Input, Select, Spin, Switch, Radio, Tabs } from "antd";
import { Pane } from "evergreen-ui";
import "antd/dist/antd.css";
import moment from "moment";
import { useLocalStorage } from "@rehooks/local-storage";
import ForceGraph2D from "react-force-graph-2d";

const initFirebase = () => {
  // Set the configuration for your app
  // TODO: Replace with your project's config object
  var firebaseConfig = {
    apiKey: "AIzaSyDgK88b7Oh31aGqYrbgbkfNkr4gKrQGXco",
    authDomain: "kudum-2d764.firebaseapp.com",
    databaseURL: "https://kudum-2d764.firebaseio.com",
    projectId: "kudum-2d764",
    storageBucket: "kudum-2d764.appspot.com",
    messagingSenderId: "53825476047",
    appId: "1:53825476047:web:add58358d3f96850762eba",
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  // Get a reference to the database service
  // firebase.database()
};

function App() {
  initFirebase();
  return <AppContent />;
}

const getSubtree = (entities, ancestorKey, includeSpouses) => {
  if (!ancestorKey) {
    return entities;
  }
  const subtreeEntities = new Set();
  addToTreeWithChildren(
    ancestorKey,
    subtreeEntities,
    entities,
    includeSpouses,
    true
  );
  return subtreeEntities;
};

const addToTreeWithChildren = (
  targetKey,
  subtreeEntities,
  allEntities,
  includeSpouses,
  isFirst
) => {
  const entity = allEntities.find((entity) => entity.key === targetKey);
  subtreeEntities.add(entity);
  entity.children.forEach((childKey) =>
    addToTreeWithChildren(
      childKey,
      subtreeEntities,
      allEntities,
      includeSpouses,
      false
    )
  );
  if (includeSpouses || isFirst) {
    entity.partners.forEach((partnerKey) => {
      const partnerEntity = allEntities.find(
        (eachEntity) => eachEntity.key === partnerKey
      );
      if (partnerEntity) {
        subtreeEntities.add(partnerEntity);
      }
    });
  }
};

const AppContent = () => {
  const [entities, loading] = useListVals(
    firebase
      .database()
      .ref("families")
      .child("guru")
      .child("entities")
  );
  const [filledEntities, setFilledEntities] = useState([]);
  const [filledSubtreeEntities, setFilledSubtreeEntities] = useState([]);
  const [userId, setUserId] = useLocalStorage("_k_userId");
  const [shouldVisualize, setShouldVisualize] = useState(false);
  const [ancestor, setAncestor] = useState(null);
  const [includeSpouses, setIncludeSpouses] = useState(true);
  const [shouldUpdate, setShouldUpdate] = useState(null);

  // const [cleared, setCleared] = useState(false);

  useEffect(() => {
    if (!loading) {
      setFilledEntities(fillRelationshipsForEntities(entities));
    }
  }, [entities, loading]);

  useEffect(() => {
    if (ancestor !== null) {
      // setIncludeSpouses(!ancestor);
      setShouldUpdate(true);
    }
  }, [ancestor]);

  useEffect(() => {
    if (shouldUpdate !== false && filledEntities.length) {
      setFilledSubtreeEntities([...getSubtree(filledEntities, ancestor, true)]);
      setShouldUpdate(false);
    }
  }, [shouldUpdate]);

  useEffect(() => {
    if (filledEntities.length) {
      setShouldUpdate(true);
    }
  }, [filledEntities]);

  // useEffect(() => {
  //   if (filledEntities.length) {
  //     setFilledSubtreeEntities([
  //       ...getSubtree(filledEntities, ancestor, includeSpouses),
  //     ]);
  //   }
  // }, [filledEntities, includeSpouses, ancestor]);

  useEffect(() => {
    if (userId) {
      window.analytics.identify(userId);
    }
  }, [userId]);

  if (loading) {
    return (
      <Pane className="App" padding={40}>
        <Spin />
      </Pane>
    );
  }

  if (!userId) {
    return <Login setUserId={setUserId} entities={entities} />;
  }

  return (
    <Pane className="App" padding={40}>
      <Pane display="flex" justifyContent="center">
        <Pane width={320}>
          <Tabs
            style={{ justifyContent: "center" }}
            defaultActiveKey="members"
            onChange={(key) => setShouldVisualize(key === "visualize")}
          >
            <Tabs.TabPane tab="Family Members" key="members" />
            <Tabs.TabPane tab="Visualize" key="visualize" />
          </Tabs>
        </Pane>
      </Pane>

      <TreeSelect
        entities={filledEntities}
        setAncestor={setAncestor}
        ancestor={ancestor}
        includeSpouses={includeSpouses}
        setIncludeSpouses={setIncludeSpouses}
        setShouldUpdate={setShouldUpdate}
        shouldVisualize={shouldVisualize}
      />

      {shouldVisualize && !!ancestor && (
        <Visualize2 ancestor={ancestor} entities={filledSubtreeEntities} />
        // <Visualize ancestor={ancestor} entities={filledSubtreeEntities} />
      )}
      {shouldVisualize && !ancestor && (
        // <Visualize2 ancestor={ancestor} entities={filledSubtreeEntities} />
        <Visualize ancestor={ancestor} entities={filledSubtreeEntities} />
      )}
      {!shouldVisualize && (
        <>
          <FamilyMembers entities={filledSubtreeEntities} />
          <AddMemberForm entities={filledSubtreeEntities} />
        </>
      )}
    </Pane>
  );
};

const findEntityByKey = (entities, key) =>
  entities.find((entity) => entity.key === key);

const Visualize2 = ({ ancestor, entities }) => {
  return (
    <Pane display="flex" justifyContent="start">
      <BlockWithChildren entities={entities} entityKey={ancestor} isAncestor />
    </Pane>
  );
};

const BlockWithChildren = ({ entities, entityKey, isAncestor }) => {
  const entity = findEntityByKey(entities, entityKey);
  if (!entity) {
    return <></>;
  }
  return (
    <Pane
      key={entityKey}
      borderRadius={8}
      backgroundColor="rgba(0,0,0,0.01)"
      margin={4}
    >
      <Pane
        display="flex"
        justifyContent="center"
        alignItems="stretch"
        position="relative"
      >
        <EntityBlock entity={entity} hasAncestor={!isAncestor} />
        {!!entity.partners.length && entity.children.length > 1 && (
          <Pane
            position="absolute"
            borderBottom="1px solid #ddd"
            left={0}
            right={0}
            bottom={5}
          ></Pane>
        )}

        {entity.partners.map((partnerKey) => (
          <>
            <EntityBlock
              key={partnerKey}
              marginLeft={0}
              entity={findEntityByKey(entities, partnerKey)}
              isPartner
            />
          </>
        ))}
      </Pane>
      <Pane display="flex" justifyContent="center" alignItems="flex-start">
        {[...entity.children]
          .sort((a, b) => {
            console.log(a.birthYear, b.birthYear, a, b);
            if (a.birthYear === b.birthYear) {
              return 0;
            }
            if (!a.birthYear) {
              return 1;
            }
            if (!b.birthYear) {
              return -1;
            }
            if (a.birthYear > b.birthYear) {
              return 1;
            }
            return -1;
          })
          .map((childKey) => (
            <BlockWithChildren
              key={childKey}
              entities={entities}
              entityKey={childKey}
            />
          ))}
      </Pane>
    </Pane>
  );
};

const EntityBlock = ({ entity, hasAncestor, isPartner, ...props }) => {
  return (
    <Pane
      backgroundColor="white"
      borderRadius={4}
      borderWidth={1}
      borderStyle="solid"
      borderColor={getGenderColor(entity.gender)}
      width={120}
      justifySelf="center"
      paddingY={8}
      paddingX={16}
      margin={12}
      position="relative"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      {...props}
    >
      {hasAncestor && (
        <Pane
          position="absolute"
          height={22}
          top={-23}
          left="50%"
          borderRight="1px solid #ddd"
        ></Pane>
      )}
      {isPartner && (
        <>
          <Pane
            position="absolute"
            marginLeft={-12}
            width={12}
            borderTopWidth={1}
            borderTopStyle="solid"
            borderTopColor="#ddd"
            left={-1}
            top="50%"
          ></Pane>
          <Pane
            position="absolute"
            borderRight="1px solid #ddd"
            width={6}
            marginRight={5}
            marginTop={0}
            marginLeft={-12}
            bottom={-8}
            left={0}
            top="50%"
          ></Pane>
        </>
      )}
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        fontWeight={500}
      >
        {getEntityName(entity)}
      </Pane>
      <Pane fontSize={10}>
        {entity.birthUTC ? moment(entity.birthUTC).format("YYYY.MM.DD") : ""}
      </Pane>
      {entity.deathUTC && (
        <Pane fontSize={10}>
          {moment(entity.deathUTC).format("YYYY.MM.DD")}
        </Pane>
      )}
    </Pane>
  );
};

const TreeSelect = ({
  entities,
  setAncestor,
  ancestor,
  setIncludeSpouses,
  includeSpouses,
  setShouldUpdate,
  shouldVisualize,
}) => (
  <Pane
    display="flex"
    justifyContent="center"
    position={shouldVisualize ? "static" : "static"}
    left={20}
    top={20}
  >
    <Pane
      width={320}
      paddingY={8}
      borderRadius={8}
      marginBottom={20}
      backgroundColor="#fafafa"
    >
      <Pane paddingY={8}>
        <Select
          style={{ minWidth: 200 }}
          onChange={(selected) => setAncestor(selected)}
          defaultValue={ancestor || ""}
        >
          <Select.Option key="">Select ancestor: show all</Select.Option>

          {entities
            .filter((entity) => entity.children.length)
            .map((entity) => (
              <Select.Option key={entity.key}>
                {getEntityName(entity)}
              </Select.Option>
            ))}
        </Select>
      </Pane>
      <Pane paddingY={8}>
        Include spouses
        <Switch
          disabled={!ancestor}
          style={{ marginLeft: 8 }}
          checked={includeSpouses}
          onChange={(value) => {
            setIncludeSpouses(value);
            setShouldUpdate(true);
          }}
        />
      </Pane>
    </Pane>
  </Pane>
);

const Visualize = ({ entities, ancestor }) => {
  const links = [];
  entities.forEach((entity) => {
    if (entity.children) {
      entity.children.forEach((child) => {
        links.push({
          source: entity.key,
          target: child,
        });
      });
    }
  });

  const graphData = {
    nodes: entities.map((entity) => ({
      ...entity,
      id: entity.key,
      name: getEntityName(entity),
      color: getGenderColor(entity.gender),
    })),
    links,
  };

  return (
    <>
      <VisualizeContent graphData={graphData} />
    </>
  );
};

const VisualizeContent = ({ graphData }) => {
  const [form, setForm] = useState("");
  return (
    <Pane>
      <Pane position="fixed" zIndex={100} right={20} top={100}>
        <Radio.Group onChange={(e) => setForm(e.target.value)}>
          <Radio.Button value="">Freeform</Radio.Button>
          <Radio.Button value="td">Tree</Radio.Button>
          <Radio.Button value="radialout">Radial</Radio.Button>
        </Radio.Group>
      </Pane>
      <ForceGraph2D
        graphData={graphData}
        dagMode={form || null}
        linkDirectionalParticles={2}
        linkDirectionalParticleWidth={2}
        d3VelocityDecay={0.3}
      />
    </Pane>
  );
};

const getGenderColor = (gender) => {
  // const BLUE = "#a4ecff";
  // const PINK = "#fdaed8";

  const BLUE = "hsla(203, 70%, 82%, 1)";
  const PINK = "hsla(328, 45%, 84%, 1)";

  const PURPLE = "hsla(260, 95%, 84%, 1)";
  const genderToColor = {
    male: BLUE,
    female: PINK,
    other: PURPLE,
  };
  return genderToColor[gender];
};

const Login = ({ entities, setUserId }) => {
  return (
    <Pane className="App" padding={40}>
      <Pane>Choose who to log in as:</Pane>
      <Select
        style={{ width: "100%" }}
        onChange={(selected) => setUserId(selected)}
      >
        {entities.map((eachEntity) => (
          <Select.Option key={eachEntity.key}>
            {getEntityName(eachEntity)}
          </Select.Option>
        ))}
      </Select>
    </Pane>
  );
};

const fillRelationshipsForEntities = (entities) => {
  // fill children
  // fill siblings
  const parents = {};
  const partners = {};

  entities.forEach((entity) => {
    if (entity.parents) {
      entity.parents.forEach((parentKey) => {
        if (!parents[parentKey]) {
          parents[parentKey] = new Set();
        }
        if (!partners[parentKey]) {
          partners[parentKey] = new Set();
        }
        parents[parentKey].add(entity.key);
        entity.parents
          .filter((eachParentKey) => eachParentKey !== parentKey)
          .forEach((eachParentKey) => {
            partners[parentKey].add(eachParentKey);
          });
      });
    }
  });

  return entities.map((entity) => {
    const siblingSet = new Set();
    Object.values(parents).forEach((siblings) => {
      if (siblings.has(entity.key)) {
        siblings.forEach(
          (sibling) => sibling !== entity.key && siblingSet.add(sibling)
        );
      }
    });

    return {
      ...entity,
      children: [...(parents[entity.key] || [])],
      siblings: [...siblingSet],
      partners: [...(partners[entity.key] || [])],
    };
  });
};

const getEntityName = (entity) => {
  if (entity.firstName && entity.middleName && entity.lastName) {
    return entity.firstName + " " + entity.middleName + " " + entity.lastName;
  }

  if (entity.firstName && entity.lastName) {
    return entity.firstName + " " + entity.lastName;
  }
  if (entity.firstName || entity.nicknames) {
    return entity.firstName || `(${entity.nicknames})`;
  }
  return "-";
};

const FamilyMembers = ({ entities }) => {
  console.log(entities);
  return (
    <Pane display="flex" justifyContent="center">
      <Pane
        paddingTop={12}
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
      >
        {entities.map((entity) => (
          <Entity key={entity.key} entity={entity} entities={entities} />
        ))}
      </Pane>
    </Pane>
  );
};

const Entity = ({ entity, entities }) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <Pane
      marginBottom={isEditing ? 20 : 0}
      position="relative"
      paddingX={20}
      width={340}
    >
      <Pane
        onClick={() => setIsEditing(!isEditing)}
        backgroundColor="#f2f2f2"
        borderRadius={40}
        marginBottom={8}
        padding={8}
        cursor="pointer"
      >
        {getEntityName(entity)}
      </Pane>
      {isEditing && (
        <>
          <EditMemberForm
            entity={entity}
            closeForm={() => setIsEditing(false)}
            entities={entities}
          />

          <Pane
            borderWidth={1}
            borderStyle="solid"
            borderColor="hsla(0, 0%, 55%, 1)"
            position="absolute"
            top={18}
            bottom={10}
            left={0}
            right={0}
            border="1px solid black"
            zIndex={-1}
          />
        </>
      )}
    </Pane>
  );
};

const EditMemberForm = ({ entity, closeForm, entities }) => {
  const onDelete = () => {
    firebase
      .database()
      .ref("families")
      .child("guru")
      .child("entities")
      .child(entity.key)
      .remove();
  };

  const onSubmit = (form) => {
    console.log(form);
    // console.log(form.birthdate.format());
    const entityRef = firebase
      .database()
      .ref("families")
      .child("guru")
      .child("entities")
      .child(entity.key);
    const entityData = {
      firstName: form.firstName || "",
      middleName: form.middleName || "",
      lastName: form.lastName || "",
      nicknames: form.nicknames || "",
      gender: form.gender || "",
      parents: form.parents || [],
    };

    if (form.birthdate) {
      entityData.birthDate = parseInt(form.birthdate.format("D"));
      entityData.birthMonth = parseInt(form.birthdate.format("M"));
      entityData.birthYear = parseInt(form.birthdate.format("YYYY"));
      entityData.birthUTC = form.birthdate.format();
    }
    if (form.deathdate) {
      entityData.deathDate = parseInt(form.deathdate.format("D"));
      entityData.deathMonth = parseInt(form.deathdate.format("M"));
      entityData.deathYear = parseInt(form.deathdate.format("YYYY"));
      entityData.deathUTC = form.deathdate.format();
    }

    console.log(entityData);
    entityRef.update(entityData);
    // newRef.set(entityData);
    closeForm();
  };

  return (
    <Pane marginBottom={20}>
      <FamilyMemberForm
        title="Edit family member"
        entity={entity}
        onSubmit={onSubmit}
        entities={entities}
        onDelete={onDelete}
      />
    </Pane>
  );
};

const AddMemberForm = ({ entities }) => {
  const onSubmit = (form) => {
    console.log(form);
    // console.log(form.birthdate.format());
    const entityRef = firebase
      .database()
      .ref("families")
      .child("guru")
      .child("entities");
    const newRef = entityRef.push();
    const entityData = {
      firstName: form.firstName || "",
      middleName: form.middleName || "",
      lastName: form.lastName || "",
      nicknames: form.nicknames || "",
      gender: form.gender || "",
      parents: form.parents || [],
      key: newRef.key,
    };
    if (form.birthdate) {
      entityData.birthDate = parseInt(form.birthdate.format("D"));
      entityData.birthMonth = parseInt(form.birthdate.format("M"));
      entityData.birthYear = parseInt(form.birthdate.format("YYYY"));
      entityData.birthUTC = form.birthdate.format();
    }
    if (form.deathdate) {
      entityData.deathDate = parseInt(form.deathdate.format("D"));
      entityData.deathMonth = parseInt(form.deathdate.format("M"));
      entityData.deathYear = parseInt(form.deathdate.format("YYYY"));
      entityData.deathUTC = form.deathdate.format();
    }

    console.log(entityData);
    newRef.set(entityData);
  };

  return (
    <Pane marginTop={20} display="flex" justifyContent="center">
      <Pane
        width={300}
        borderTopWidth={1}
        borderTopStyle="solid"
        borderTopColor="black"
        paddingTop={20}
      >
        <FamilyMemberForm
          title="Add family member"
          onSubmit={onSubmit}
          entities={entities}
        />
      </Pane>
    </Pane>
  );
};

const FamilyMemberForm = ({ onSubmit, onDelete, title, entity, entities }) => {
  const { handleSubmit, control } = useForm();

  const defaultValues = entity || {};

  return (
    <Pane marginTop={12}>
      <h3>{title}</h3>
      <Pane
        is="form"
        onSubmit={handleSubmit(onSubmit)}
        display="flex"
        justifyContent="center"
      >
        <Pane
          display="flex"
          flexDirection="column"
          width={300}
          paddingBottom={20}
        >
          <Field>
            <Controller
              as={Input}
              name="firstName"
              defaultValue={defaultValues.firstName}
              placeholder="First Name"
              control={control}
            />
            <Controller
              as={<Input style={{ marginLeft: 8, width: "60%" }} />}
              name="middleName"
              defaultValue={defaultValues.middleName}
              placeholder="Middle"
              control={control}
            />
          </Field>
          <Field>
            <Controller
              as={Input}
              name="lastName"
              defaultValue={defaultValues.lastName}
              placeholder="Last Name"
              control={control}
            />
          </Field>
          <Field>
            <Controller
              as={Input}
              name="nicknames"
              defaultValue={defaultValues.nicknames}
              placeholder="Nicknames"
              control={control}
            />
          </Field>
          <Field>
            <Controller
              as={
                <Radio.Group style={{ width: "100%", display: "flex" }}>
                  <Radio.Button value="female" style={{ width: "100%" }}>
                    Female
                  </Radio.Button>
                  <Radio.Button value="male" style={{ width: "100%" }}>
                    Male
                  </Radio.Button>
                  <Radio.Button value="other" style={{ width: "100%" }}>
                    Other
                  </Radio.Button>
                </Radio.Group>
              }
              name="gender"
              defaultValue={defaultValues.gender}
              onChange={([selected]) => selected.target.value}
              control={control}
            />
          </Field>
          <Field>
            <Controller
              as={<DatePicker style={{ width: "100%" }} />}
              name="birthdate"
              defaultValue={
                defaultValues.birthUTC ? moment(defaultValues.birthUTC) : ""
              }
              placeholder="Birth Date"
              defaultPickerValue={moment().subtract(20, "years")}
              onChange={([selected]) => selected}
              control={control}
            />
          </Field>
          <Field>
            <Controller
              as={<DatePicker style={{ width: "100%" }} />}
              name="deathdate"
              defaultValue={
                defaultValues.deathUTC ? moment(defaultValues.deathUTC) : ""
              }
              placeholder="Death Date"
              defaultPickerValue={moment().subtract(20, "years")}
              onChange={([selected]) => selected}
              control={control}
            />
          </Field>
          <Field>
            <Controller
              name="parents"
              as={
                <Select mode="multiple" style={{ width: "100%" }}>
                  {entities.map((eachEntity) => (
                    <Select.Option key={eachEntity.key}>
                      {getEntityName(eachEntity)}
                    </Select.Option>
                  ))}
                </Select>
              }
              placeholder="Parents"
              defaultValue={defaultValues.parents || []}
              onChange={([selected]) => selected}
              control={control}
            />
          </Field>
          {defaultValues.children && (
            <Field>
              <Select
                placeholder="Children"
                disabled
                mode="multiple"
                style={{ width: "100%" }}
                value={defaultValues.children}
              >
                {entities.map((eachEntity) => (
                  <Select.Option key={eachEntity.key}>
                    {getEntityName(eachEntity)}
                  </Select.Option>
                ))}
              </Select>
            </Field>
          )}
          {defaultValues.siblings && (
            <Field>
              <Select
                placeholder="Siblings"
                disabled
                mode="multiple"
                style={{ width: "100%" }}
                value={entity.siblings}
              >
                {entities.map((eachEntity) => (
                  <Select.Option key={eachEntity.key}>
                    {getEntityName(eachEntity)}
                  </Select.Option>
                ))}
              </Select>
            </Field>
          )}
          <Field>
            <SubmitButton />

            {onDelete && (
              <Pane
                onClick={onDelete}
                marginLeft={8}
                cursor="pointer"
                backgroundColor="white"
                color="#666"
                borderStyle="solid"
                borderWidth={1}
                borderColor="#888"
                borderRadius={8}
                paddingY={8}
                paddingX={12}
                fontSize={12}
              >
                Delete
              </Pane>
            )}
          </Field>
        </Pane>
      </Pane>
    </Pane>
  );
};

const Field = (props) => (
  <Pane display="flex" width="100%" marginBottom={10} {...props} />
);

const SubmitButton = (props) => (
  <Pane
    is="input"
    type="submit"
    cursor="pointer"
    borderWidth={0}
    backgroundColor="rgb(0, 153, 255)"
    color="white"
    borderRadius={8}
    paddingY={8}
    paddingX={12}
    fontSize={12}
    fontWeight={600}
  />
);

export default App;
